import { Pipe, PipeTransform } from '@angular/core';
import lodash from 'lodash';

@Pipe({
    name: 'amountToWords',
    standalone: true
})
export class AmountToWordsPipe implements PipeTransform {

  amountWord:string;
  transform(amount: any): any {
    amount = (lodash.round(amount, 2)).toFixed(2);
    amount = amount.toString();
    const th = ['','Thousand','Million', 'Billion','Trillion'];
    const dg = ['zero','one','two','three','four', 'five','six','seven','eight','nine'];
    const tn = ['ten','eleven','twelve','thirteen', 'fourteen','fifteen','sixteen', 'seventeen','eighteen','nineteen'];
    const tw = ['twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
    amount = amount.replace(/[\, ]/g,'');

    amount = amount.toString();
    if (amount != parseFloat(amount)) return 'not a number';

    // To avoid e^ values
    amount = Number(amount).toLocaleString('fullwide', {useGrouping:false}) 

    let x = amount.indexOf('.');
    if (x == -1) x = amount.length;
    if (x > 15) return 'Too big';
    var n = amount.split('');
    let str = '';
    var sk = 0;
    str = this.converToWords(x,n,str,tn,sk,tw,dg,th)

    if (str)
      str += 'Dollars';

    if (x != amount.length)
    {
      const cents = amount.split('.');
      const cent = cents[1];
      var n = cent.split('');
      let str2 = '';
      var sk = 0;
      const y = cent.length;
      let i:number;
      str2 = this.converToWords(y,n,str2,tn,sk,tw,dg,th);
      if(str2.length > 0)
        str = (str ? (str + ' and ') : '') + str2 + ' Cents ';
    }
    this.amountWord = str.replace(/\s+/g,' ')+' Only ';
    this.amountWord = this.amountWord.charAt(0).toUpperCase() + this.amountWord.slice(1); ;
    return Number(amount) === 0 ? 'Zero Dollars Only' : this.amountWord;
  }
  converToWords(x,n,str,tn,sk,tw,dg,th):string{
    for (let i=0; i < x; i++)
    {
      if ((x-i)%3==2)
      {
        if (n[i] == '1')
        {
          str += tn[Number(n[i+1])] + ' ';
          i++;
          sk=1;
        }
        //todo
          else if (n[i] === '-') {

        }
        else if (n[i]!=0)
        {
          str += tw[n[i]-2] + ' ';
          sk=1;
        }
      }
      //todo
      else if (n[i] === '-') {

      }
      else if (n[i]!=0)
      {
        str += dg[n[i]] +' ';
        if ((x-i)%3==0) str += 'Hundred ';
        sk=1;
      }

      if ((x-i)%3==1)
      {
        if (sk) str += th[(x-i-1)/3] + ' ';
        sk=0;
      }
    }
    return str;
  }

}
